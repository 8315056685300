var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-book"},[_c('div',{staticClass:"order-book__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('v-btn',{staticClass:"ml-12 mt-3",attrs:{"color":"primary","outlined":"","height":"40px"},on:{"click":_vm.loadData}},[_vm._v(" "+_vm._s(_vm.$t('Refresh orderbook'))+" ")])],1),_c('div',{staticClass:"order-book__table"},[_c('UiTable',{attrs:{"data":_vm.preparedData,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"fixed-header":"","height":"527px","show-extra":"","row-styles":_vm.rowStyles},scopedSlots:_vm._u([{key:"bidsNumber",fn:function(ref){
var row = ref.row;
return [(row.bids)?[_vm._v(" "+_vm._s(row.bids.orders.length)+" ")]:_vm._e()]}},{key:"bidsTotal",fn:function(ref){
var row = ref.row;
return [(row.bids)?[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.bids.totalQty))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.baseTitle))])]:_vm._e()]}},{key:"bidsAmount",fn:function(ref){
var row = ref.row;
return [(row.bidsAmount)?[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.bidsAmount))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.baseTitle))])]:_vm._e()]}},{key:"bidsPrice",fn:function(ref){
var row = ref.row;
return [(row.bids)?[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.bids.price))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.quoteTitle))])]:_vm._e()]}},{key:"asksNumber",fn:function(ref){
var row = ref.row;
return [(row.asks)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(row.asks.orders.length)+" ")]):_vm._e()]}},{key:"asksTotal",fn:function(ref){
var row = ref.row;
return [(row.asks)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.asks.totalQty))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.baseTitle))])]):_vm._e()]}},{key:"asksAmount",fn:function(ref){
var row = ref.row;
return [(row.asksAmount)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.asksAmount))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.baseTitle))])]):_vm._e()]}},{key:"asksPrice",fn:function(ref){
var row = ref.row;
return [(row.asks)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$utils.sciToDec(row.asks.price))+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.quoteTitle))])]):_vm._e()]}},{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row},on:{"cancel":_vm.cancelOrder}})]}}])}),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-row',{staticClass:"body-2 pl-4 pr-4"},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('Total:'))+" "+_vm._s(_vm.data.bidsTotalPrice)+" "+_vm._s(_vm.quoteTitle)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('Total:'))+" "+_vm._s(_vm.data.asksTotalAmount)+" "+_vm._s(_vm.baseTitle)+" ")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from './TradingOrderBookExtraTable.vue';
import tableConfig from './tableExtraConfig';

export default {
  components: { Table },

  props: {
    data: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loader: false,
      tableConfig,
    };
  },

  computed: {
    bids() {
      const { bids } = this.data;
      if (!bids) return null;
      return bids.orders;
    },

    asks() {
      const { asks } = this.data;
      if (!asks) return null;
      return asks.orders;
    },
  },

  methods: {
    cancel(id) {
      this.$emit('cancel', id);
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading-orders"},[_c('div',{staticClass:"d-flex justify-space-between trading-orders__filters"},[_c('div',{staticClass:"d-flex"},[_c('TradingOrdersFilters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('GetReport',{attrs:{"type":_vm.constant.reports.REPORT_TYPE.ORDERS,"command":_vm.requestData}})],1)]),_c('div',{staticClass:"trading-orders__table"},[_c('Table',{attrs:{"selection":_vm.selection,"is-selected-all":_vm.isSelectedAll,"data":_vm.tradingOrders,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-select":"","show-actions":"","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"update:isSelectedAll":function($event){_vm.isSelectedAll=$event},"update:is-selected-all":function($event){_vm.isSelectedAll=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"extra",fn:function(ref){
var row = ref.row;
return [(_vm.hasTrades(row.orderStatus))?_c('TradingOrdersExtra',{attrs:{"order-id":row.id,"side":row.side}}):_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.$t('There is no trades in the current order'))+" ")])]}},{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"trading-orders__date"},[_c('div',{staticClass:"trading-orders__date-wrapper"},[_c('div',{staticClass:"text-no-wrap trading-orders__created"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]),_c('div',{staticClass:"trading-orders__id"},[_c('span',{staticClass:"text--secondary"},[_vm._v("Id: "+_vm._s(row.id))])])])])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [(row.side === _vm.constant.orders.ORDER_SIDE.BID)?_c('span',{staticClass:"success--text"},[_vm._v("+"+_vm._s(row.orderQty))]):_vm._e(),(row.side === _vm.constant.orders.ORDER_SIDE.ASK)?_c('span',{staticClass:"error--text"},[_vm._v("-"+_vm._s(row.orderQty))]):_vm._e()]}},{key:"filled",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"trading-orders__filled"},[_vm._v(" "+_vm._s(row.orderQty - row.leavesQty)+" "),(row.filled !== 0)?_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(row.filled.toFixed())+"%")]):_vm._e(),(row.orderStatus === _vm.constant.orders.ORDER_STATUS.CANCELLED)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('(cancelled)'))+" ")]):_vm._e()])]}},{key:"condition",fn:function(ref){
var row = ref.row;
return [(row.stopPrice)?[_vm._v(" "+_vm._s(row.side === _vm.constant.orders.ORDER_SIDE.ASK ? '>=' : '<=')+" "+_vm._s(row.stopPrice)+" ")]:_vm._e()]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"api",fn:function(ref){
var row = ref.row;
return [(row.apiKeyId)?_c('router-link',{attrs:{"to":{
            name: 'user-api',
            params: {
              id: row.userId,
            },
            query: {
              keysKeyId: row.apiKeyId,
            },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(row.apiKeyId)+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('Pagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"infinite-pages":""},on:{"input":_vm.setPage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
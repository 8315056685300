//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import User from '~/components/User.vue';
import tableConfig from './tradingOrdersInnerTableConfig';

export default {

  components: { User },
  props: {
    orderId: { type: Number, required: true },
    side: { type: Number, required: true },
  },

  data() {
    return {
      loader: true,
      tableConfig,
    };
  },

  computed: {
    ...mapState('tradingOrders', ['innerTrades']),

    currentInnerTradeList() {
      const { innerTrades, orderId } = this;
      const current = innerTrades.find((e) => e.orderId === orderId);
      if (current) {
        return current.tradeList;
      }
      return [];
    },
  },

  async mounted() {
    this.setGeneralProgress(true);
    await this.loadInnerTrades({
      orderIdList: [this.orderId],
    });
    this.loader = false;
    this.setGeneralProgress(false);
  },

  methods: {
    ...mapActions('tradingOrders', ['loadInnerTrades']),
    ...mapActions('common', ['setGeneralProgress']),
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import TradingOrders from '~/views/Trading/TradingOrders/TradingOrders.vue';
import TradingTrades from '~/views/Trading/TradingTrades/TradingTrades.vue';
import TradingOrderBook from '~/views/Trading/TradingOrderBook/TradingOrderBook.vue';

export default {
  components: {
    TradingOrders,
    TradingTrades,
    TradingOrderBook,
  },

  computed: {
    ...mapState('currencies', ['markets']),
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getTradingOrders(),
      this.getTradingTrades(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('tradingOrders', {
      getTradingOrders: 'getTradingOrders',
    }),
    ...mapActions('tradingTrades', {
      getTradingTrades: 'loadData',
    }),
  },
};

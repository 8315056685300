export default [
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Number of orders',
    },
    cell: {
      type: 'slot',
      name: 'bidsNumber',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Total',
    },
    cell: {
      type: 'slot',
      name: 'bidsTotal',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'slot',
      name: 'bidsAmount',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Price',
    },
    cell: {
      type: 'slot',
      name: 'bidsPrice',
    },
  },

  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Price',
      className: 'order-book__asks-header',
    },
    cell: {
      type: 'slot',
      name: 'asksPrice',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Amount',
      className: 'order-book__asks-header',
    },
    cell: {
      type: 'slot',
      name: 'asksAmount',
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Total',
      className: 'order-book__asks-header',
    },
    cell: {
      type: 'slot',
      name: 'asksTotal',
    },
  },
  {
    width: 17,
    header: {
      type: 'text',
      caption: 'Number of orders',
      className: 'order-book__asks-header',
    },
    cell: {
      type: 'slot',
      name: 'asksNumber',
    },
  },
];

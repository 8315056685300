//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        marketIdList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        amountFrom: null,
        amountTo: null,
        priceFrom: null,
        priceTo: null,
        ignoreUserTagList: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('currencies', ['markets']),
    ...mapState('tradingTrades', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters({
          ...data,
          ignoreUserTagList: data.ignoreUserTagList
            ? constant.reports.IGNORE_USER_TAG_LIST
            : [],
        });
        this.$emit('update');
      },
      deep: true,
    },
  },

  mounted() {
    const {
      TradesSearchString,
    } = this.$route.query;
    if (TradesSearchString) {
      this.filters = {
        ...this.filters,
        searchString: TradesSearchString || '',
      };
      this.setFilters(this.filters);
      this.$emit('update');
      this.$refs.tradingTrades.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      this.setFilters({});
    }
  },

  methods: {
    ...mapActions('tradingTrades', ['setFilters']),
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading-trades-extra"},[_c('UiTable',{attrs:{"data":_vm.currentInnerOrdersList,"config":_vm.tableConfig,"is-loading":_vm.loader,"per-page":2,"class-name":"pt-4 pb-4"},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"trading-trades-extra__date"},[_c('div',{staticClass:"trading-trades-extra__date-wrapper"},[_c('div',{staticClass:"text-no-wrap trading-trades-extra__created"},[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.dateCreated))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.dateCreated)))])]),_c('div',{staticClass:"trading-trades-extra__id"},[_c('span',{staticClass:"text--secondary"},[_vm._v("Id: "+_vm._s(row.id))])])])])]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"amount",fn:function(ref){
var row = ref.row;
return [(row.side === _vm.constant.orders.ORDER_SIDE.BID)?_c('span',{staticClass:"success--text"},[_vm._v("+"+_vm._s(row.orderQty))]):_vm._e(),(row.side === _vm.constant.orders.ORDER_SIDE.ASK)?_c('span',{staticClass:"error--text"},[_vm._v("-"+_vm._s(row.orderQty))]):_vm._e()]}},{key:"filled",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"trading-trades-extra__filled"},[_vm._v(" "+_vm._s(row.orderQty - row.leavesQty)+" "),(row.filled !== 0)?_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(row.filled.toFixed())+"%")]):_vm._e(),(row.orderStatus === _vm.constant.orders.ORDER_STATUS.CANCELLED)?_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('(cancelled)'))+" ")]):_vm._e()])]}},{key:"condition",fn:function(ref){
var row = ref.row;
return [(row.stopPrice)?[_vm._v(" "+_vm._s(row.side === _vm.constant.orders.ORDER_SIDE.ASK ? '>=' : '<=')+" "+_vm._s(row.stopPrice)+" ")]:_vm._e()]}},{key:"ip",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ipAddress)+" ")]}},{key:"api",fn:function(ref){
var row = ref.row;
return [(row.apiKeyId)?_c('router-link',{attrs:{"to":{
          name: 'user-api',
          params: {
            id: row.userId,
          },
          query: {
            keysKeyId: row.apiKeyId,
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(row.apiKeyId)+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
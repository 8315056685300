import utils from '../../../utils';

export default [
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Trade Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Market',
    },
    cell: {
      type: 'text',
      value: (e) => utils.getMarketById(e.marketId).name,
    },
  },
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Taker',
    },
    cell: {
      type: 'slot',
      name: 'taker',
    },
  },
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Maker',
    },
    cell: {
      type: 'slot',
      name: 'maker',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Fee',
    },
    cell: {
      type: 'slot',
      name: 'fee',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Price',
      sort: 'price',
    },
    cell: {
      type: 'slot',
      name: 'price',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Amount',
      sort: 'amount',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Summ',
    },
    cell: {
      type: 'text',
      value: (e) => e.price * e.amount,
    },
  },
];

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import User from '~/components/User.vue';
import tableConfig from './tableExtraConfig';

export default {

  components: {
    User,
  },

  props: {
    data: { type: Array, default: () => [] },
  },
  data() {
    return {
      tableConfig,
    };
  },

  methods: {
    completedPercent(row) {
      const { orderQty, leavesQty } = row;
      const percent = ((orderQty - leavesQty) / orderQty) * 100;
      return percent.toFixed();
    },

    cancelOrder(id) {
      this.$emit('cancel', id);
    },

    showOrder(id) {
      window.scrollTo(0, 0);

      const { ordersSearchString } = this.$route.query;
      if (ordersSearchString === id) return;

      this.$router.replace({
        query: {
          ordersSearchString: id,
        },
      });
    },
  },
};

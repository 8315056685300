//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './TradingOrderBookFilters.vue';
import Extra from './TradingOrderBookExtra.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, Extra },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('currencies', ['markets']),
    ...mapGetters('currencies', ['getCurrencyById']),

    ...mapState('tradingOrderBook', [
      'page',
      'pages',
      'data',
      'filters',
      'sort',
    ]),

    preparedData() {
      const { bids, asks } = this.data;
      if (!bids || !asks) return [];

      const maxLength = Math.max(bids.length, asks.length);
      const preparedData = [];

      let i = 0;
      const totalCounter = {
        bids: 0,
        asks: 0,
      };

      while (i < maxLength) {
        const basicProps = {
          bidsAmount: 0,
          bidsTotal: 0,
          asksAmount: 0,
          asksTotal: 0,
        };

        if (bids[i]) {
          bids[i].orders.forEach((e) => {
            basicProps.bidsAmount += e.leavesQty;
          });
          totalCounter.bids += basicProps.bidsAmount;
          basicProps.bidsTotal = totalCounter.bids;
        }
        if (asks[i]) {
          asks[i].orders.forEach((e) => {
            basicProps.asksAmount += e.leavesQty;
          });
          totalCounter.asks += basicProps.asksAmount;
          basicProps.asksTotal = totalCounter.asks;
        }

        preparedData[i] = {
          id: i,
          ...basicProps,
          bids: bids[i],
          asks: asks[i],
        };
        i += 1;
      }
      return preparedData;
    },

    currentMarket() {
      const { marketId } = this.filters;
      const { markets } = this;
      const market = markets.find((e) => e.id === marketId);

      return market;
    },

    baseTitle() {
      const { currentMarket } = this;
      if (!currentMarket) return '';

      const base = this.getCurrencyById(currentMarket.baseCurrencyId);
      return base.title;
    },

    quoteTitle() {
      const { currentMarket } = this;
      if (!currentMarket) return '';

      const quote = this.getCurrencyById(currentMarket.derivedCurrencyId);
      return quote.title;
    },

    rowStyles() {
      const { data, preparedData } = this;
      const bidsTotal = data.bidsTotalAmount; // this.getTotalAmount(data.bids);
      const asksTotal = data.asksTotalAmount; // this.getTotalAmount(data.asks);
      const bidColor = 'rgba(76,175,80,0.25)';
      const askColor = 'rgba(255,82,82,0.25)';
      const emptyColor = 'rgba(255,255,255,0)';
      const percents = preparedData.map((e) => ({
        bid: ((e.bids ? e.bids.totalQty : 0) / bidsTotal) * 100,
        ask: ((e.asks ? e.asks.totalQty : 0) / asksTotal) * 100,
      }));
      const styles = percents.map((e) => {
        const bidFrom = (100 - (e.bid || 0)) / 2;
        const askTo = ((e.ask || 0) / 2) + 50;
        const style = {
          background: `linear-gradient(to right, ${emptyColor} ${bidFrom}%, ${bidColor} ${bidFrom}% 50%, ${askColor} 50% ${askTo}%, ${emptyColor} ${askTo}%)`,
        };
        return style;
      });
      return styles;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradingOrderBook', {
      loadDataAction: 'loadData',
      cancelOrderAction: 'cancelOrder',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    cancelOrder(id) {
      const timeOut = async () => (new Promise((r) => setTimeout(r, 3000)));

      this.confirmAction({
        title: 'Are you sure you want to cancel order?',
        callback: async () => {
          this.setGeneralProgress(true);
          await this.cancelOrderAction([id]);
          this.loader = true;
          await timeOut(); // таймаут необходим, т. к. ордербук на бэке обновляется с задержкой
          await this.loadDataAction({});
          this.loader = false;
          this.setGeneralProgress(false);
          this.setSuccessNotification('Order successfully canceled');
        },
      });
    },

    // getTotalAmount(data) {
    //   let total = 0;
    //   data.forEach((e) => {
    //     e.orders.forEach((o) => {
    //       total += o.leavesQty;
    //     });
    //   });
    //   return total;
    // },
  },
};

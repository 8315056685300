//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import constant from '~/const';
import Filters from '~/components/filters/Index.vue';
import TextField from '~/components/ui/TextField.vue';
import Select from '~/components/ui/Select.vue';
import DateRange from '~/components/ui/DateRange.vue';
import InputRange from '~/components/ui/InputRange.vue';

export default {

  components: {
    Filters,
    TextField,
    Select,
    DateRange,
    InputRange,
  },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        marketIdList: [],
        side: null,
        orderTypeList: [],
        dateCreatedFrom: '',
        dateCreatedTo: '',
        orderStatusList: [],
        orderQtyFrom: '',
        orderQtyTo: '',
        priceFrom: '',
        priceTo: '',
        filledFrom: '',
        filledTo: '',
        ignoreUserTagList: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('currencies', ['markets']),
    ...mapState('tradingOrders', ['filterLists']),

    ordersSearchString() {
      const { ordersSearchString } = this.$route.query;
      return ordersSearchString;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters({
          ...data,
          ignoreUserTagList: data.ignoreUserTagList
            ? constant.reports.IGNORE_USER_TAG_LIST
            : [],
        });
        this.$emit('update');
      },
      deep: true,
    },

    ordersSearchString(value) {
      if (value) {
        this.filters.searchString = `${value}`;
        this.$router.replace({ query: {} });
      }
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradingOrders', ['setFilters']),
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var row = ref.row;
return [_c('v-icon',{staticClass:"mr-3 orders__remove",attrs:{"color":"error"},on:{"click":function($event){return _vm.cancelOrder(row.id)}}},[_vm._v(" mdi-close ")]),_c('User',{attrs:{"id":row.userId,"username":row.userName}})]}},{key:"completed",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.orderQty - row.leavesQty)+" "),_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.completedPercent(row))+"%")])]}},{key:"remaining",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.leavesQty)+" ")]}},{key:"id",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"orders__id",on:{"click":function($event){return _vm.showOrder(row.id)}}},[_vm._v(" "+_vm._s(row.id)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
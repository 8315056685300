export default [
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'user',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Completed',
    },
    cell: {
      type: 'slot',
      name: 'completed',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Remaining',
    },
    cell: {
      type: 'slot',
      name: 'remaining',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'slot',
      name: 'id',
    },
  },
];

import utils from '../../../utils';

export default [
  {
    width: 14,
    header: {
      type: 'text',
      caption: 'Date',
      sort: 'dateCreated',
    },
    cell: {
      type: 'slot',
      name: 'date',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'slot',
      name: 'name',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Market',
    },
    cell: {
      type: 'text',
      value: (e) => utils.getMarketById(e.marketId).name,
    },
  },
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'text',
      value: (e) => utils.getOrderTypeById(e.orderType).caption,
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Time-in-Force',
    },
    cell: {
      type: 'slot',
      name: 'condition',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Price',
      sort: 'price',
    },
    cell: {
      type: 'text',
      value: (e) => (e.price ? utils.sciToDec(e.price) : utils.sciToDec(e.averagePrice)),
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Amount',
      sort: 'orderQty',
    },
    cell: {
      type: 'slot',
      name: 'amount',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Average price',
      sort: 'averagePrice',
    },
    cell: {
      type: 'text',
      value: (e) => (e.averagePrice ? utils.sciToDec(e.averagePrice) : '–'),
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Filled',
      sort: 'filled',
    },
    cell: {
      type: 'slot',
      name: 'filled',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'IP',
    },
    cell: {
      type: 'slot',
      name: 'ip',
    },
  },
  {
    width: 5,
    header: {
      type: 'text',
      caption: 'API',
    },
    cell: {
      type: 'slot',
      name: 'api',
    },
  },
];

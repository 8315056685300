//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        marketId: 0,
      },
      constant,
    };
  },

  computed: {
    ...mapState('tradingOrderBook', ['filterLists']),
    ...mapState('currencies', ['markets']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    const { id } = this.markets[0];
    this.filters.marketId = id;
  },

  methods: {
    ...mapActions('tradingOrderBook', ['setFilters']),
  },
};
